/* hamburger x span */
.btn-hamburger_is-visible__0K_2_ .btn-hamburger_header__menu-icon__sjd_w {
	background-color: transparent;
	transition-delay: 0s;
}

.btn-hamburger_is-visible__0K_2_ .btn-hamburger_header__menu-icon__sjd_w:before {
	margin-top: 0px;
	--tw-rotate: 45deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transition-delay: 0s, 0.2s;
}

.btn-hamburger_is-visible__0K_2_ .btn-hamburger_header__menu-icon__sjd_w:after {
	margin-top: 0px;
	--tw-rotate: -45deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transition-delay: 0s, 0.2s;
}

.btn-hamburger_header__menu-btn__aEJ8j {
	height: 2rem;
	width: 2rem;
	background-color: transparent;
}

.btn-hamburger_header__menu-btn__aEJ8j:hover .btn-hamburger_header__menu-icon__sjd_w {
	--tw-bg-opacity: 1;
	background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.btn-hamburger_header__menu-btn__aEJ8j:hover .btn-hamburger_header__menu-icon__sjd_w:before,
.btn-hamburger_header__menu-btn__aEJ8j:hover .btn-hamburger_header__menu-icon__sjd_w:after {
	--tw-bg-opacity: 1;
	background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

/* hamburger / x menu button */
.btn-hamburger_header__menu-icon__sjd_w {
	/* pointer events none required to avoid event toggle issues in js */
	pointer-events: none;
	width: 1.5rem;
	outline: 2px solid transparent;
	outline-offset: 2px;
	transition-duration: 0s;
	transition-delay: 0.2s;
	transition-property: transform;
	height: 3px;
}

.btn-hamburger_header__menu-icon__sjd_w:before,
.btn-hamburger_header__menu-icon__sjd_w:after {
	position: absolute;
	display: block;
	width: 100%;
	height: 3px;
	content: '';
}

.btn-hamburger_header__menu-icon__sjd_w:before {
	margin-top: -8px;
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;
}

.btn-hamburger_header__menu-icon__sjd_w:after {
	margin-top: 9px;
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;
}

/* this class has to be added separately so that it doesn't happen until after first click on the button */
.btn-hamburger_header__menu-btn--transition__XmYsn {
	height: 2rem;
	width: 2rem;
	transition: all 0.4s ease;
}

/* these have to be set of offset all the grid stuff wrappped around header */
.SiteNav_header___4sl2 {
	/* the z-20 combats the axpert advice page z-10s on the articles */
	z-index: 20;
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-self: flex-start;
}

.SiteNav_nav-is-visible__6Dhvk .SiteNav_nav-pages__G2imX {
	visibility: visible;
}

.SiteNav_nav-pages__G2imX a {
	display: block;
	gap: 2rem;
	font-size: 2.25rem;
	line-height: 2.5rem;
}

@media (min-width: 1280px) {
	.SiteNav_nav-pages__G2imX a {
		border-radius: 1.5rem;
		padding-left: 1.75rem;
		padding-right: 1.75rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
}

.SiteNav_nav-pages__G2imX a:hover {
	--tw-text-opacity: 1;
	color: rgb(7 89 133 / var(--tw-text-opacity));
}

@media (min-width: 1280px) {
	.SiteNav_nav-pages__G2imX a:hover {
		--tw-bg-opacity: 1;
		background-color: rgb(249 249 249 / var(--tw-bg-opacity));
	}
}

/* nav variant */
.SiteNav_nav-dark-variant__7_obU.SiteNav_header___4sl2 {
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 5%, rgba(0, 0, 0, 0.80) 100%);
}

@media (min-width: 1280px) {
	.SiteNav_nav-dark-variant__7_obU.SiteNav_header___4sl2 .SiteNav_nav-pages__G2imX a {
		--tw-text-opacity: 1;
		color: rgb(255 255 255 / var(--tw-text-opacity));
	}
	.SiteNav_nav-dark-variant__7_obU.SiteNav_header___4sl2 .SiteNav_nav-pages__G2imX a:hover {
		background-color: rgb(255 255 255 / var(--tw-bg-opacity));
		--tw-bg-opacity: 0.15;
		--tw-text-opacity: 1;
		color: rgb(255 255 255 / var(--tw-text-opacity));
	}
}

/* this is the slideout portion of the nav */

.SiteNav_nav-drawer__U5KF1 {
	pointer-events: auto;
	position: absolute;
	left: 0px;
	top: 0px;
	display: flex;
	height: 100%;
	min-height: 0px;
	width: 100%;
	flex-basis: 100%;
	--tw-translate-x: 100%;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	flex-direction: column;
	justify-content: center;
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	padding-left: 2rem;
	padding-right: 2rem;
	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
	will-change: transform;
}

@media (min-width: 1280px) {
	.SiteNav_nav-drawer__U5KF1 {
		width: 24rem;
	}
}

.SiteNav_nav-drawer__U5KF1 {
	contain: paint;
	height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	z-index: -1;
	transition: transform 0.3s;
	/* visible/invisible will need to be here for proper accessibility */
}

.SiteNav_nav-is-visible__6Dhvk .SiteNav_nav-drawer__U5KF1 {
	/* visible/invisible toggle is so that the links inside the tab auto add or remove themselves from tabbing order based on if the nav is open or closed */
	/* visibility: visible; */
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	transition: transform 0.3s;
	transform: translateX(0%);
}

@media (min-width: 1280px) {
	.SiteNav_nav-drawer__U5KF1 {
		position: static;
		display: flex;
		height: auto;
		width: auto;
		flex-direction: row;
		justify-content: flex-end;
		background-color: transparent;
		padding-top: 0px;
		transform: unset;
	}
}

/* the following is a working solution for accesibility */
.SiteNav_nav-pages__G2imX {
	visibility: hidden;
}

@media (min-width: 1280px) {
	.SiteNav_nav-pages__G2imX {
		visibility: visible;
	}
}

.SiteNav_nav-social__YXM_c {
	visibility: hidden;
}

.SiteNav_nav-is-visible__6Dhvk .SiteNav_nav-social__YXM_c {
	visibility: visible;
}

@media (min-width: 1024px) {
	.SiteNav_nav-social__YXM_c {
		visibility: visible;
	}
}

@media (min-width: 1280px) {
	.SiteNav_nav-drawer__U5KF1 {
		padding: 0px;
	}
}

.SiteNav_fixed-header__QVuL_ {
	position: fixed;
	z-index: 10;
	width: 100%;
	--tw-backdrop-blur: blur(16px);
	-webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	/* forces Safari to use GPU */
	transform: translate3d(0, 0, 0, 0);
	/* tells Safari to use less optimizations when rendering */
	will-change: backdrop-filter;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-inter: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

/* vietnamese */
@font-face {
  font-family: '__Space_Grotesk_0aa4ae';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/62328fecf9e80426-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Space_Grotesk_0aa4ae';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/c7eb187887c48af6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Space_Grotesk_0aa4ae';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/2d141e1a38819612-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Space_Grotesk_Fallback_0aa4ae';src: local("Arial");ascent-override: 88.82%;descent-override: 26.36%;line-gap-override: 0.00%;size-adjust: 110.78%
}.__className_0aa4ae {font-family: '__Space_Grotesk_0aa4ae', '__Space_Grotesk_Fallback_0aa4ae';font-style: normal
}.__variable_0aa4ae {--font-space-grotesk: '__Space_Grotesk_0aa4ae', '__Space_Grotesk_Fallback_0aa4ae'
}

